<template>
  <div class="subtitle">
    <i>"{{ this.randomCite.cite }}"</i> ― {{ this.randomCite.author }}
  </div>
</template>

<script>
export default {
  name: "Cites",
  data() {
    return {
      cites: [
        { cite: "Talk is cheap. Show me the code.", author: "Linus Torvalds" },
        {
          cite:
            "Programs must be written for people to read, and only incidentally for machines to execute.",
          author: "Harold Abelson"
        },
        {
          cite:
            "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
          author: "Martin Fowler"
        }
      ]
    };
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  computed: {
    randomCite() {
      const rand = this.getRandomInt(0, 2);
      console.log("Random:", rand);
      return this.cites[rand];
    }
  }
};
</script>

<style lang="scss">
.subtitle {
  font-size: 1.7rem;
  float: right;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
</style>
